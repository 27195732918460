import * as React from "react";
import { Helmet } from 'react-helmet';
import '../assets/css/pages/apps-page.css';
import NewsItem from "../components/NewsItem";
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import NewsData from '../data/newsData.json';
import ReactGA from 'react-ga';

const NewsPage = () => {
  const renderNews = () => {
    const newsItems = NewsData.news.map(({id, title, timestamp, mainImageName, description }) => {
      return (
        <NewsItem key={id} id={id} title={title} timestamp={timestamp} mainImageName={mainImageName} description={description}/>
      );
    });
    return(
      <React.Fragment>
        {newsItems}
      </React.Fragment>
    )
  }

  React.useEffect(() => {
  	ReactGA.initialize('UA-88504612-1');
  	ReactGA.pageview("/news");
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Pixel Works Software: News & Updates</title>
        <meta name="description" content="Stay updated with the latest news and announcements from Pixel Works Software about our mobile apps and game solvers." />
        <meta name="keywords" content="news, updates, pixel works software, mobile apps, game solvers" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.pixelworkssoftware.com/news" />
        <meta property="og:title" content="Pixel Works Software: News & Updates" />
        <meta property="og:description" content="Stay updated with the latest news and announcements from Pixel Works Software about our mobile apps and game solvers." />
        <meta property="og:image" content="https://www.pixelworkssoftware.com/app-icon.png" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.pixelworkssoftware.com/news" />
        <meta name="twitter:title" content="Pixel Works Software: News & Updates" />
        <meta name="twitter:description" content="Stay updated with the latest news and announcements from Pixel Works Software about our mobile apps and game solvers." />
        <meta name="twitter:image" content="https://www.pixelworkssoftware.com/app-icon.png" />
        
        {/* Canonical URL */}
        <link rel="canonical" href="https://www.pixelworkssoftware.com/news" />
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.NEWS} />
      <main>
        <h1 className="visually-hidden">Pixel Works Software News & Updates</h1>
        {renderNews()}
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default NewsPage;
